import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TextSection from "../components/textSection"
import IconSection from "../components/iconSection"
import ImageGridSection from "../components/imageGridSection"
import ContactSection from "../components/contactSection"
import DescriptionSection from "../components/descriptionSection"
import Timeline from "../components/timeline"
import Layout from "../components/layout"

// import "./o-nas.css"

const AboutUsPage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="O nas" data={data.header} />
      </AnimationWrapper>


      {data.page.frontmatter.text_sections[0] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[0]} />
          </AnimationWrapper>
        </Container>
      }

      <section id="about-us-desc" className="page-section">
        {data.page.frontmatter.description_sections.map((description_section) => (
            <DescriptionSection location="about-us" data={description_section} />
        ))}
      </section>

      <section id="onas-timeline" className="page-section">
        <Container>
          <Timeline name="onas" data={data.page.frontmatter.timeline} />
        </Container>
      </section>

      <section id="outsourcing-icons">
        <Container>
          <AnimationWrapper>
            <IconSection data={data.page.frontmatter.icons_section[0]} icons={data.page.frontmatter.icons} location="homepage" />
          </AnimationWrapper>
        </Container>
      </section>

      {data.page.frontmatter.text_sections[1] &&
        <Container>
          <AnimationWrapper>
            <TextSection className="description-medium" data={data.page.frontmatter.text_sections[1]} />
          </AnimationWrapper>
        </Container>
      }

      <section id="klienci-grid-section" className="page-section">
        <ImageGridSection location="klienci" imagesCount={15} btnText="Pokaż więcej >" data={data.page.frontmatter.klienci_grid}/>
      </section>

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section[0]} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "o-nas" }, type: { eq: "page" } }) {
      frontmatter{
        title
        text_sections{
          title
          title_visible
          subtitle
          # icon{
          #   childImageSharp {
          #     gatsbyImageData(
          #       transformOptions: {fit: COVER},
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          text
        }
        description_sections{
          title
          title_visible
          subtitle
          type
          text
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        timeline{
          title
          title_visible
          subtitle
          type
          arrangement
          text
          timestamps{
            title
            background_color
            color
            desc
          }
        }
        icons_section{
          title
          title_visible
          subtitle
          type
          # image{
          #   childImageSharp{
          #     gatsbyImageData(
          #       webpOptions: {quality: 95}
          #     )
          #   }
          # }
          text
        }
        icons{
          title
          text
          image{
            childImageSharp {
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
        klienci_grid{
          title
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
          description
        }
        contact_section{
          title
          description
          type
          fields
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "page-headers/o-nas.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default AboutUsPage
