import * as React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import AnimationWrapper from "./animationWrapper"
import TextSection from "./textSection"

import "./timeline.css"

const Timeline = ( { data, name } ) => {

    return (
      <AnimationWrapper className={`${name}-timeline timeline-wrapper ${data.type} ${data.arrangement}`}>

        {data.title_visible &&
          <TextSection className="timeline-title" data={data} />
        }

        <div className="timestamps-container">
          {data.timestamps.map((timestamp) => (
            <div className="timestamp">

              <div className="timestamp-title-wrapper">

                {/* <div className="timestamp-title-before" style={{background: `${timestamp.background_color}`}}></div> */}

                {/* <h3 className="timestamp-title" style={{background: `${timestamp.background_color}`}}> */}
                <h3 className="timestamp-title" style={{color: `${timestamp.color}`}}>
                  {timestamp.title}
                </h3>

                {/* <div className="timestamp-title-after" style={{borderLeft: `30px solid ${timestamp.background_color}`}}></div> */}

              </div>

              <div className="timestamp-content" style={{color: `${timestamp.color}`}}>

                <div className="timestamp-content-before" style={{borderColor: `${timestamp.background_color}`, backgroundColor: `${timestamp.background_color}`}}></div>

                {timestamp.desc &&
                  <div className="timestamp-desc">
                    {timestamp.desc}
                  </div>
                }

                {timestamp.icons &&
                  <div className="timestamp-icons">
                    {timestamp.icons.map((icon) => (
                      <div className="icon-wrapper">
                        <GatsbyImage
                          image={icon.image.childImageSharp.gatsbyImageData}
                          alt={icon.title}
                        />
                        <div className="icon-txt-wrapper">
                          <h4 className="icon-title">
                            {icon.title}
                          </h4>
                          {icon.text &&
                            <p className="icon-desc">
                              {icon.text}
                            </p>
                          }
                        </div>
                      </div>
                    ))}
                  </div>
                }

              </div>

            </div>
          ))}
        </div>

      </AnimationWrapper>
    )
}

export default Timeline